<template>
  <v-card-text>
    <v-row>
      <v-col cols="2">
        <v-select 
          prepend-icon="mdi-calendar"
          v-model="year"
          :items="years"
          item-text="texto"
          item-value="id"
          label="Año"
          color="blue darken-3"
          persistent-hint
          @change="loadClientes"
        >
        </v-select>
      </v-col>
      <v-col cols="2">
        <v-select 
          prepend-icon="mdi-calendar"
          v-model="mes"
          :items="meses"
          item-text="texto"
          item-value="id"
          label="Mes"
          color="blue darken-3"
          persistent-hint
          return-object
          @change="loadClientes"
        >
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4" v-for="item in data" v-bind:key="item.id">
        <div class="card" style="margin-bottom:0;">
          <div class="card-header card-header-icon card-header-rose">
            <div class="card-icon" style="padding: 5px 10px 5px;">
              <h4>{{ item.empresa.razon_social }}</h4>
            </div>
          </div>
          <div class="card-body">
            <v-row>
              <v-col cols="6">
                <div class="table-responsive">
                  <table class="table" style="margin-bottom:0;">
                    <tbody>
                      <tr>
                        <td>Ventas USD</td>
                        <td class="number">{{ formatNumber(item.real.ventas, 0) }}</td>
                      </tr>
                      <tr>
                        <td>Biomasa (ton)</td>
                        <td class="number">{{ formatNumber(item.real.biomasa, 0) }}</td>
                      </tr>
                      <tr>
                        <th colspan="2">Target vs Real (%)</th>
                      </tr>
                      <tr>
                        <td>Ventas USD</td>
                        <td class="number">{{ item.comparacion.ventas }}</td>
                      </tr>
                      <tr>
                        <td>Biomasa (ton)</td>
                        <td class="number">{{ item.comparacion.biomasa }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </v-col>
              <v-col cols="6">
                <vc-donut 
                  background="white" foreground="grey"
                  :size="100" unit="px" :thickness="30"
                  has-legend legend-placement="top"
                  :sections="item.vtaData" :total="100"
                  :start-angle="0" :auto-adjust-text-size="true"
                >Ventas</vc-donut>
                <vc-donut 
                  background="white" foreground="grey"
                  :size="100" unit="px" :thickness="30"
                  has-legend legend-placement="top"
                  :sections="item.bioData" :total="100"
                  :start-angle="0" :auto-adjust-text-size="true"
                >Biomasa</vc-donut>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-card-text>
</template>
<style scoped>
  .card{
    margin-top: 0;
  }
  .card .card-header-rose .card-icon{
    background: #263238;
    box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(32, 30, 31, 0.822);
  }
  .number{
    text-align:right;
  }
</style>
<script>
  import {mapState, mapMutations} from 'vuex'
  export default {
    data: () => ({
      data: [],
      url_logo: '',
      meses: [
        {id: 0, 'texto': 'Todos'},
        {id: 1, 'texto': 'Enero'},
        {id: 2, 'texto': 'Febrero'},
        {id: 3, 'texto': 'Marzo'},
        {id: 4, 'texto': 'Abril'},
        {id: 5, 'texto': 'Mayo'},
        {id: 6, 'texto': 'Junio'},
        {id: 7, 'texto': 'Julio'},
        {id: 8, 'texto': 'Agosto'},
        {id: 9, 'texto': 'Septiembre'},
        {id: 10, 'texto': 'Octubre'},
        {id: 11, 'texto': 'Noviembre'},
        {id: 12, 'texto': 'Diciembre'},
      ],
      mes: 0,
      years: [],
      year: 0,
      sections: [
        { label: 'Proyectado', value: 75, color: 'blue' },
        { label: 'Real', value: 25, color: 'gray' },
      ]
    }),
    mounted() {
      this.year  = this.moment().format("YYYY");
      this.years = [this.year - 1, this.year];
      this.mes   = this.meses[this.moment().format("M")];
      this.loadClientes();
    },
    methods:{
      async loadClientes(){
        let url = `${this.base_url}empresas/dashboard/${this.year}/${this.mes.id}`;
        await this.axios.get(url, this.headers).then((response)=>{
          this.data = response.data;
          this.url_logo = this.base_url.substring(0, this.base_url.length - 11) + 'storage/app/';
        }).catch((error)=>{
          console.log(error);
        });
      },
      formatNumber(numero, decimales = 2){ //, checknegative = false){
        //if(checknegative && numero < 0) return 'Número Inválido';
        let arrNum = numero.toString().split('.');
        if(arrNum[1] == undefined) arrNum[1] = "";
        let num = arrNum[0];
        if(!isNaN(num)){
          num = num.split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.');
          num = num.split('').reverse().join('').replace(/^[.]/,'');
          let dec = arrNum[1].padEnd(decimales, '0');
          if(decimales > 0) num += ',' + dec;
          return num;
        }else{
          return '0';
        }
      },
      ...mapMutations(['actualizarBaseUrl', 'mostrarLoading', 'ocultarLoading']),
    },
    computed:{
      ...mapState(['loading', 'base_url', 'headers', 'wellboat']),
    },
    components:{
    }
  }
</script>
